import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import LoginHeader from './components/Header/LoginHeader';
import IdleTimeout from './IdleTimeout';
import WarningBanner from './components/Header/WarningBanner';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './components/Dashboards/Dashboard';
import SurveyContainer from './components/SurveyContainer/SurveyContainer';
import { SurveyProvider } from './components/SurveyContainer/SurveyContext';
import ApiTesting from './testing/ApiTesting';
import { withNavigation } from './withNavigation';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            env: '',
            loginUrl: '',
            logoutUrl: '',
            user: '',
            nameTag:'',
            warned: false,
            ddbConfigurations_IdleTimeout: [],
            sessionID: '',
            isWarningBannerVisible: true,
            machine: new URL(window.location.href).origin,
            isSidebarExpanded: true,
            displayBanner: false
        };
    }

    componentDidMount() {
        fetch('/api/sessionInfo')
            .then(response => {
                if(response.ok) {
                    return response.json();
                } else {
                    this.setState({displayBanner:true});
                    this.fetchLoginURL();
                    fetch('/environment')
                        .then(response => response.json())
                        .then(json => {
                            this.setState({ env: json.env });
                        });
                    fetch('/status')
                        .then(response => response.json())
                        .then(json => {
                        });
                }
            })
            .then(json => {
                if(json) {
                    this.setState({displayBanner: false});
                    let sessionInfo = json.data;
                    if (sessionInfo['idP_validated'] || sessionStorage.getItem('initialLoad')) {
                        if (!sessionStorage.getItem('initialLoad')) {
                            console.log('Starting session Clock');
                            sessionStorage.setItem('sessionStartTime', Date.now());
                            sessionStorage.setItem('initialLoad', true);
                        }
                        this.setState({ isWarningBannerVisible: false });
                        this.setApplicationLevelValues(sessionInfo);
                        this.fetchConfigurations();
                    }
                }
            })
            .catch(error => {
                console.log("error response: ", error);
                if (error.name === 'AbortError') {
                    console.error('Request timed out');
                }
            });
    }

    fetchLoginURL = () => {
        let opts = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                machine: this.state.machine,
            })
        };
        fetch(`/auth/loginUrl`, opts)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    loginUrl: json.login,
                    logoutUrl: '',
                    user: '',
                    access_token: null,
                });
            });
    }

    loginHarporCognito = () => {
        window.open(this.state.loginUrl, '_self');
    }

    setApplicationLevelValues = (sessionInfo) => {
        let user = sessionInfo['id'];
        let logoutUrl = sessionInfo['logout'];
        let sessionID = sessionInfo['sessionID'];
    
        this.fetchEncryptedUserInfo()
            .then(nonEncryptedUserName => {
            if (nonEncryptedUserName) {

                function capitalizeFirstLetterOfEachWord(str) {
                    return str.split(' ').map(word => {
                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                    }).join(' ');
                }

                const capitalizedNameTag = capitalizeFirstLetterOfEachWord(nonEncryptedUserName);
                this.setState({ nameTag: capitalizedNameTag });
            } else {

                this.setState({ nameTag: 'Jonathan Jones' });
            }
            })
                
        this.setState({
            user: user,
            logoutUrl: logoutUrl,
            sessionID: sessionID
        });
    }

    fetchEncryptedUserInfo = async () => {
        try {
            const response = await fetch(`/api/decryptEncyptedUserInfo`);
            if (!response.ok) {
                throw new Error('Unable to decrypt User Information');
            }
            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error(error);
            return null; // Return null or some default value in case of error
        }
    };

    fetchConfigurations = () => {
        this.triggerDDBTimeoutConfig();
    }

    triggerDDBTimeoutConfig = () => {
        fetch(`/api/idle_timeout/getDDBConfigurations`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                this.setState({ 
                    ddbConfigurations_IdleTimeout: data,
                });
                let forceLogout = data[0].forceLogout;
                if (forceLogout === true) {
                    this.sharedLogout();
                }
            })
            .catch(error => {
                console.error('Failed to fetch configuration:', error);
            });
    }


    sharedLogout = () => {
        let logoutUrl = this.state.logoutUrl;
        sessionStorage.removeItem('sessionStartTime');
        sessionStorage.removeItem('initialLoad');
        sessionStorage.removeItem('elementsTabCollapsedStates');
        sessionStorage.removeItem('elementsTabScrollPosition');
        sessionStorage.removeItem('idP_validated')

        this.setState({ isWarningBannerVisible: true });
        this.fetchLoginURL();
        window.open(logoutUrl, '_self');
    }

    setWarned = (w) => {
        this.setState({ warned: w });
    }
    toggleSidebar = () => {
        this.setState((prevState) => ({
            isSidebarExpanded: !prevState.isSidebarExpanded
        }));
    }

    render() {
        let ddbWarningTime = this.state.ddbConfigurations_IdleTimeout.length !== 0 ? this.state.ddbConfigurations_IdleTimeout[0].maxInactiveTimeBeforeWarning : 25;
        let ddbLogoutTime = this.state.ddbConfigurations_IdleTimeout.length !== 0 ? this.state.ddbConfigurations_IdleTimeout[0].maxInactiveTimeBeforeLogout : 30;
        const isDdbDataAvailable = this.state.ddbConfigurations_IdleTimeout.length !== 0;
        const { location } = this.props; 
        console.log(location.pathname)
        if (!this.state.isWarningBannerVisible) {

            return (
                <>
                    <IdleTimeout
                        maxInactiveTimeBeforeWarning={ddbWarningTime}
                        maxInactiveTimeBeforeLogout={ddbLogoutTime}
                        ddbData={isDdbDataAvailable}
                        onTimeout={this.sharedLogout}
                    />

                    <Header {...this.state} sharedLogout={this.sharedLogout} />
                    <Container fluid style={{ height: '100vh'}}>
                        <Row style={{ height: '100%' }}>
                            <Col hidden={location.pathname === '/status'} md={1} style={{ width: this.state.isSidebarExpanded ? '150px' : '50px', padding: 0 }}>
                                <Sidebar
                                    isExpanded={this.state.isSidebarExpanded}
                                    toggleExpand={this.toggleSidebar}
                                />
                            </Col>

                            <Col style={{ padding: 0 }}>
                                <Routes>
                                    <Route path="/surveys" element={<SurveyProvider>
                                        <SurveyContainer />
                                    </SurveyProvider>} />
                                    <Route path="/dashboards" element={<Dashboard board='newsurveys' />} />
                                    <Route path="/status" element={<ApiTesting/>}/>
                                    <Route path="/" element={<Navigate to="/dashboards" />} />
                                </Routes>
                            </Col>
                        </Row>
                    </Container>
                    <br />
                </>
            );

        } else if (this.state.isWarningBannerVisible) {
            if(this.state.displayBanner) {
                return (
                    <Container className='p-1'>
                        <WarningBanner
                            warned={this.state.warned}
                            setWarned={this.setWarned}
                        />
                        <LoginHeader
                            loginHarporCognito={this.loginHarporCognito}
                        />
                    </Container>
                );
            } else {
                return (<></>);
            }
        }
    }
}

export default withNavigation(App);