import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './SurveyColorVariables.css'

const SurveyContext = createContext();
export const useSurvey = () => useContext(SurveyContext);

//!This can go away when we pull in real entityData. 
import {fetchDefaultFilter} from "../../common/utils";

/*  
  Survey Provider is used to share functions and state easily with components in the Survey Container.
*/

export const SurveyProvider = ({ children }) => {

  //State stores selected entities from the actions menu. 
  const [selectedEntities, setSelectedEntities] = useState([]);
  //Annotations stores the entities highlights objects for a deficiency text.
  const [annotations, setAnnotations] = useState([]);
  //surveyDefList stores the survey/def items in the Survey Selection panel.
  const[surveyDefList, setSurveyDefList] = useState([]);
  const[surveyCount, setSurveyCount] = useState(0);
  // State for deficiency text
  const [defText, setDefText] = useState('');
  const scrollRef = useRef(null); // Single ref for the current section
  const [sections, setSections] = useState([]); // Manage sections here
  const sectionsRef = useRef([]); // Refs for the sections
  const [selectedFilter, setSelectedFilter] = useState(fetchDefaultFilter());
  const [scrollSurveyListToTop, setScrollSurveyListToTop] = useState(false);
  const [selectedDeficiency, setSelectedDeficiency] = useState({});
  const [surveysLoading, setSurveysLoading] = useState(false);
  const [surveyCountLoading, setSurveyCountLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const handleEntityCheckboxClick = (tag, type) => {
    //Handles changing the checkbox selection state for the selected Entities in the action menu.
    // console.log(`Handle Entity Checkbox ${type} Click: ${tag}`)
    if (type === 'all') {
      setSelectedEntities((prevSelectedEntities) => {
        if (tag.every(entity => prevSelectedEntities.includes(entity))) {
          return []; 
        } else {
          return tag; //tag here contains all entities.
        }
      });
    } else {
      setSelectedEntities((prevSelectedEntities) => {
        if (prevSelectedEntities.includes(tag)) {
          return prevSelectedEntities.filter((t) => t !== tag); //handles an uncheck of a single entity.
        } else {
          return [...prevSelectedEntities, tag]; //handles an check of a single entity.
        }
      });
    }
  };

  const scrollToSecton = useCallback((index) => {
    console.log(sectionsRef.current)
    console.log(sectionsRef.current[index])
    if (sectionsRef.current && sectionsRef.current[index]) {
      console.log(sectionsRef.current)
      console.log(sectionsRef.current[index])
        sectionsRef.current[index].scrollIntoView({ behavior: 'smooth' });
    } else {
        console.error(`No element found for index: ${index}`);
    }
}, []);

const updateSections = (newSections) => {
  setSections(newSections);
};



    const getColorForEntity = useCallback((entity) => {
      //Detemines the color for the entities box and the highlights for that specific entity.
      //Color variables are stored in the SurveyColorVariables.css file.
      const cssVariableName = `--highlight-${entity.toLowerCase()}`;

      // console.log(cssVariableName)
      const color = getComputedStyle(document.documentElement).getPropertyValue(cssVariableName).trim();
      return color || '#FFFFFF';
  }, []);

  const updateSurveyAnnotationsValues = (annotations) => {
        // Only fires when Modeling Entity Data is not empty. This will add the color tag to all annotations.
        // Also will set the annotations in state.
        if (annotations.length > 0) {
            const modifiedAnnotations = annotations.map(annotation => ({
                ...annotation,
                color: getColorForEntity(annotation.type),
                key: uuidv4() // Assigning UUID
            }));

            setAnnotations(modifiedAnnotations);
        }
    }
  const updateDeficiencyText = (defText) => {
    setDefText(defText);
  }
  const updateSurveyCount = (count) => {
    setSurveyCount(count);
    setSurveyCountLoading(true)
  }
  const updateSurveyDefList = (surveyDefList) => {
    setSurveyDefList(surveyDefList);
  }
  const updateAnnotations = (annotations) => {
    updateSurveyAnnotationsValues(annotations)
  }
  const updateSelectedDeficiency = (deficiency) => {
    setSelectedDeficiency(deficiency)
  }

  const updateSurveysLoading = (value) => {
    setSurveysLoading(value)
  }

  const updateSurveysCountLoading = (value) =>{
    setSurveyCountLoading(value)
  }

  const updateScrollSurveyListToTop = (value) => {
    setScrollSurveyListToTop(value)
  }

  const updateInitialLoadFlag = (value) => {
    setInitialLoad(value)
  }
 
      
    return (
        <SurveyContext.Provider 
          value={{ 
              //Functions
              handleEntityCheckboxClick, 
              getColorForEntity, 

              scrollToSecton,
              updateSections,
              updateDeficiencyText,
              updateSurveyDefList,
              surveyCount, updateSurveyCount,
              
              
              selectedDeficiency, updateSelectedDeficiency,
              updateInitialLoadFlag,
              scrollSurveyListToTop, updateScrollSurveyListToTop,
              
              selectedFilter, setSelectedFilter,
              initialLoad,
              
              annotations, updateAnnotations,
              defText,
              selectedEntities,
              surveyDefList,
              surveysLoading, updateSurveysLoading,
              surveyCountLoading, updateSurveysCountLoading,

              //Refs
              scrollRef, 
              sectionsRef,
              
          }}>
            {children}
        </SurveyContext.Provider>
    );
};