import axios from "axios";
import {FilterApiProps} from "./types";
import { API_KEY_MAPPINGS } from "./constants"
export const getSurveys = async (requestParams) => {
    let surveys = [];
    await axios.post('/api/survey', requestParams)
        .then(response => {
            surveys = JSON.parse(response.data.data.body);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    return surveys;
};
export const getSurveyCount = async (requestParams) => {
    let surveyCount = [];
    await axios.post('/api/survey/count', requestParams)
        .then(response => {
            surveyCount = JSON.parse(response.data.data.body);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    return surveyCount;
};


export const getSurveyDetails = async (requestParams) => {
    return await getSurveys(requestParams);
};


export const getFilterOptions = async (requestParams) => {
    let surveys = [];
    await axios.post('/api/filters', requestParams)
        .then(response => {
            surveys = JSON.parse(response.data.data.body);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    return surveys;
};

export const generateSurveysFilterObject = async (selectedFilter, props: FilterApiProps) => {
    let {
        undercitationFlag,
        startDate,
        endDate,
        selectedRegions,
        selectedStates,
        selectedFacilityNames,
        selectedCCNs,
        idrEnabled,
        iidrEnabled,
        substandardQocEnabled,
        selectedDTs,
        selectedSeverityLevels,
        selectedScopeSeverity,
        selectedRegulatoryGroups,
        selectedSurveyTypes
    } = props;
    let filter = {};
    filter[API_KEY_MAPPINGS.undercitationFlag] = undercitationFlag;
    if(startDate!=null && startDate !== "Invalid Date") filter[API_KEY_MAPPINGS.startDate] = startDate.format('MM/DD/YYYY');
    if(endDate!=null && endDate !== "Invalid Date") filter[API_KEY_MAPPINGS.endDate] = endDate.format('MM/DD/YYYY');
    if(selectedRegions.length) filter.region = selectedRegions;
    if(selectedStates.length) filter.state = selectedStates;
    if(selectedFacilityNames.length) filter[API_KEY_MAPPINGS.facilityName] = selectedFacilityNames;
    if(selectedCCNs.length) filter[API_KEY_MAPPINGS.ccn] = selectedCCNs;
    filter[API_KEY_MAPPINGS.idr] = idrEnabled;
    filter[API_KEY_MAPPINGS.iidr] = iidrEnabled;
    filter[API_KEY_MAPPINGS.substandardQoc] = substandardQocEnabled;
    if(selectedDTs.length) filter[API_KEY_MAPPINGS.deficiencyTag] = selectedDTs;
    if(selectedSeverityLevels.length) filter[API_KEY_MAPPINGS.securityLevels] = selectedSeverityLevels;
    if(selectedScopeSeverity.length) filter[API_KEY_MAPPINGS.scopeSeverity] = selectedScopeSeverity;
    if(selectedRegulatoryGroups.length) filter[API_KEY_MAPPINGS.regulatoryGroup] = selectedRegulatoryGroups;
    if(selectedSurveyTypes.length) filter[API_KEY_MAPPINGS.surveyType] = selectedSurveyTypes;
    selectedFilter.filter = filter;

    return selectedFilter;
};

export const triggerGetSurveys = async (params) => {
    return await getSurveys(params); 
};

export const triggerGetSurveyCount = async (params) => {
    return await getSurveyCount(params);
};

export const triggerFilterMappings = () => {
    let regionStateMappings = [];
    const requestOptions = {
        group: 'region',
        values: 'state'
    };

    return axios.post('/api/getFilterMappings', requestOptions)
        .then(response => {
            regionStateMappings = JSON.parse(response.data.data.body);
            return regionStateMappings;
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};
